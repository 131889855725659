@import '../App.scss';

.post {
  border-radius: $corner-radius;
  min-height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: 0.5s ease-out;

  outline: none;

  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 10px;
  grid-template-rows: 2fr, 1fr;
}

.post-caption {
  padding: 0px 24px;
  flex-grow: 1;
  grid-column: 1 / 9;
  grid-row: 1;
}

.post-body {
  grid-column: 1 / 9;
  grid-row: 2;
  max-height: 800px;
}

.post-footer {
  color: $button-gray;
  font-size: $font-size;
  font-weight: bold;
  grid-column: 1 / 9;
  grid-row: 3;
}

.post-votes {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0px;
  grid-template-rows: repeat(3, 1fr);
  text-align: center;
}

.up-vote-button {
  vertical-align: middle;
  margin-right: 6px;
  width: 45px;
}

.down-vote-button {
  vertical-align: middle;
  margin-right: 6px;
  margin-left: 6px;
  width: 45px;
}

.comment-button {
  vertical-align: middle;
  margin-right: 6px;
  float: right;
  width: 45px;
}

// Needed for Chrome support
// img is not contained within parent div otherwise
.button-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

img {
  image-orientation: from-image;
}