@import '~font-awesome/scss/_variables.scss';
$fa-font-path: "~font-awesome/fonts";
@import '~font-awesome/scss/font-awesome.scss';

$primary-color-green: rgb(184, 246, 145);
$primary-color-yellow: #f4e8cb;
$complementary-pink: #f691b8;
$complementary-blue: #91def6;
$complementary-orange: #f6af91;
$button-gray: #D3D3D3;
$background-color: #fff;
$secondary-background-color: rgb(45,50,60);
$tertiary-background-color: #424242;
$corner-radius: 5px;
$font-size: 18px;
$font-size-small: 10px;
$navbar-height: 3 * $font-size;


.app-wrapper {
  background: $background-color;
  color: $primary-color-green;
  font-size: $font-size;
  height: 100%;

  display: grid;
  grid-gap: 10px;
  grid-template-areas:
    "header"
    "nav"
    "content"
    "sidebar"
    "ad"
    "footer";
}

.sidebar {
  position: sticky;
  top: $navbar-height + 15px;

  margin: $font-size;
  padding: $font-size;
  background-color: $secondary-background-color;
  border-radius: $corner-radius;
}

.button-group {
  > button {
    vertical-align: middle;
  }
  .button:not(:last-child):not(:first-child) {
    margin-left: 1px;
    margin-right: 1px;
    border-radius: 0px;
  }
  .button:last-child {
    margin-left: 1px;
    border-radius: 0px $corner-radius $corner-radius 0px;
  }
  .button:first-child {
    margin-right: 1px;
    border-radius: $corner-radius 0px 0px $corner-radius;
  }
}

.button-dropdown {
  position: absolute;
  background-color: $tertiary-background-color;
  &.closed {
    visibility: hidden;
  }
  &.open {
    visibility: visible;
  }
}
